@import "easymde/dist/easymde.min.css";

@tailwind base;

@tailwind components;

@tailwind utilities;

.sidebar {
     --sidebar-width: 250px;
    --sm-offset: 56px;
}

@media (min-width: 768px) {
    .sidebar {
        width: var(--sidebar-width);
        position: sticky;
        height: calc(100vh - var(--sm-offset));
        top: var(--sm-offset);
    }
}

.role-admin {
    background: var(--bs-red);
}

.role-super {
    background: var(--bs-yellow);
}

.role-user {
    background: var(--bs-teal);
}

.role-limited {
    background: var(--bs-cyan);
}

.htmx-indicator{
    display: none;
    opacity: 0;
    transition: opacity 500ms ease-in;
}

.htmx-request .htmx-indicator{
    opacity: 1;
    display: inline;
}

.htmx-request.htmx-indicator{
    opacity: 1;
    opacity: inline;
}

.table-inactive {
    --bs-table-bg: var(--bs-secondary-bg);
    background-image: repeating-linear-gradient(
        45deg,
        var(--fallback-b1,oklch(var(--b1))),
        var(--fallback-b1,oklch(var(--b1))) 13px,
        var(--fallback-b2,oklch(var(--b2))) 13px,
        var(--fallback-b2,oklch(var(--b2))) 14px);
}

.EasyMDEContainer .editor-toolbar {
    @apply border-gray-300 bg-gray-50 dark:border-gray-600 dark:bg-gray-700;
}

.EasyMDEContainer .editor-toolbar > button {
    @apply p-0;
    @apply text-sm font-medium text-gray-900 border-gray-300 bg-gray-50 dark:text-white dark:border-gray-600 dark:bg-gray-700;
    @apply hover:bg-gray-200 dark:hover:bg-gray-500;

}


.EasyMDEContainer .CodeMirror {
    @apply text-gray-900 border-gray-300 bg-gray-50 dark:text-gray-100 dark:border-gray-600 dark:bg-gray-700;
}


.EasyMDEContainer .CodeMirror .CodeMirror-selected {
    @apply bg-sky-200 dark:bg-sky-600;
}

.EasyMDEContainer .CodeMirror .CodeMirror-cursor {
    @apply bg-gray-900 dark:bg-gray-100;
    width: 1px;
}

.EasyMDEContainer .CodeMirror .editor-preview {
    @apply text-gray-900 border-gray-300 bg-gray-50 dark:text-gray-100 dark:border-gray-600 dark:bg-gray-700;
    @apply prose prose-gray dark:prose-invert;
}
